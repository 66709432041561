import { Injectable, inject } from '@angular/core';

import { Term, TermEdit } from '@dartsales/common/core/models/estimate-services/term';
import { TermSummary } from '@dartsales/common/core/models/estimate-services/term-summary';

import { TermEditDto, EditTermOrderDto, TermDto } from '../dto/estimate-services/term.dto';
import { IMapperFromDto } from '../mappers';
import { BasePricingOverridesMapper } from '../pricing/base-pricing-overrides.mapper';

import { TermDataMapper } from './term-data.mapper';
import { TermSummaryMapper } from './term-summary.mapper';

/** Service term mapper. */
@Injectable({
  providedIn: 'root',
})
export class TermMapper
implements IMapperFromDto<TermDto, Term> {

  private readonly termDataMapper = inject(TermDataMapper);

  private readonly termSummaryMapper = inject(TermSummaryMapper);

  private readonly basePricingOverridesMapper = inject(BasePricingOverridesMapper);

  /** @inheritdoc */
  public fromDto(dto: TermDto): Term {
    const { data } = dto;

    return new Term({
      ...this.termSummaryMapper.fromDto(dto),
      data: this.termDataMapper.fromDto(data),
      bulkUpdateStatus: dto.bulkUpdateStatus,
    });
  }

  /**
   * Map edit model to DTO.
   * @param model Term edit data.
   */
  public toEditDto(model: TermEdit): TermEditDto {
    return {
      number: model.number ?? undefined,
      properties: model.propertiesOverrides != null ?
        {
          overrides: this.basePricingOverridesMapper.toDto(model.propertiesOverrides),
        } :
        undefined,
    };
  }

  /**
   * Map term to order DTO.
   * @param data Term.
   * @param order Term order.
   */
  public mapTermToOrderDto(data: TermSummary, order: number): EditTermOrderDto {
    return {
      id: data.id,
      order,
    };
  }
}
