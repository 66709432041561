import { Injectable, inject } from '@angular/core';

import { ExpensesEmployee, ExpensesEmployeeTravelCost } from '@dartsales/common/core/models/estimate/modules/expenses/expenses-employee';
import { EditExpensesEmployee } from '@dartsales/common/core/models/estimate/modules/expenses/edit-expenses-employee';
import { CreateExpensesEmployee } from '@dartsales/common/core/models/estimate/modules/expenses/create-expenses-employee';
import { ExpensesTravelCost } from '@dartsales/common/core/models/estimate/modules/expenses/expenses-travel-cost';
import { Overridable } from '@dartsales/common/core/models/overridable';

import { CreateExpensesEmployeeDto, ExpensesEmployeeDto, EditExpensesEmployeeDto } from '../../../dto/estimate/modules/expenses/expenses-employee.dto';
import { ConfirmedOverridableMapper } from '../../../confirmed-overridable.mapper';

/** Expenses employee mapper. */
@Injectable({
  providedIn: 'root',
})
export class ExpensesEmployeeMapper {

  private readonly confirmedOverridableMapper = inject(ConfirmedOverridableMapper);

  /** @inheritdoc */
  public fromDto(
    dto: ExpensesEmployeeDto,
    travelCosts: readonly ExpensesTravelCost[],
  ): ExpensesEmployee {
    const employeeTravelCosts = travelCosts.map(travelCost => {
      const employeeCost = dto.costs.find(cost => cost.expensesTravelCostId === travelCost.id);
      const employeeOverriddenRate = dto.overrides?.find(cost => cost.expensesTravelCostId === travelCost.id);

      return new ExpensesEmployeeTravelCost({
        id: travelCost.id,
        name: travelCost.title.initial,
        quantity: employeeCost?.amount ?? 0,
        rateType: travelCost.rateType.initial,
        rate: new Overridable({
          initial: travelCost.rate.combinedValue,
          override: employeeOverriddenRate?.rate ?? travelCost.rate.override,
        }),
      });
    });

    return new ExpensesEmployee({
      id: dto.id,
      order: dto.number,
      roleId: dto.expensesLaborRoleId,
      travelCostName: dto.customName,
      quantity: dto.quantity,
      roleRateType: dto.roleRateType,
      travelCosts: employeeTravelCosts,
      isRemoved: this.confirmedOverridableMapper.fromDto(dto.isRemoved),
      name: this.confirmedOverridableMapper.fromDto(dto.name),
    });
  }

  /**
   * To create DTO.
   * @param data Data.
   */
  public toCreateDto(data: CreateExpensesEmployee): CreateExpensesEmployeeDto {
    return {
      expensesLaborRoleId: data.roleId,
    };
  }

  /**
   * To edit DTO.
   * @param data Data.
   */
  public toEditDto(data: EditExpensesEmployee): EditExpensesEmployeeDto {
    return {
      quantity: data.quantity,
      customName: data.travelCostName,
      costs: data.travelCosts.map(cost => ({
        expensesTravelCostId: cost.id,
        amount: cost.quantity,
      })),
      isRemoved: this.confirmedOverridableMapper.toDto(data.isRemoved),
      name: this.confirmedOverridableMapper.toDto(data.name),
    };
  }
}
