<mat-form-field class="no-label">
  @if (listManager.pagePagination$ | async; as pagination) {
    <dartsalesc-chips-list-with-suggested
      placeholder="Add Tags"
      suggestedText="Suggested Tags:"
      [optionsList]="(options$ | async) ?? []"
      [formControl]="formControl"
      [isLoading]="listManager.isLoading$ | async"
      (searchChange)="onSearchChange($event)"
      (goToNextPage)="listManager.nextPage()"
    />
  }
  <mat-error>
    <dartsalesc-form-control-validation-message [control]="formControl" />
  </mat-error>
</mat-form-field>
