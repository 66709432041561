import {
  BasePricingInitArgs,
  BaseUnitPricing,
} from '@dartsales/common/core/models/pricing/base-pricing';

import { ModuleLockablePercentsStatus } from '../../module-lockable-percents';

/** Custom module's pricing. */
export class CustomModulePricing extends BaseUnitPricing {
  /** ID. */
  public readonly id: string;

  /** Description. */
  public readonly description: string;

  /** Quantity. */
  public readonly quantity: number;

  /** Lockable percents status. */
  public readonly lockablePercentsStatus: ModuleLockablePercentsStatus;

  public constructor(data: CustomModulePricingInitArgs) {
    super(data);
    this.id = data.id;
    this.description = data.description;
    this.quantity = data.quantity;
    this.lockablePercentsStatus = data.lockablePercentsStatus;
  }
}

type CustomModulePricingInitArgs = CustomModulePricing & BasePricingInitArgs;
