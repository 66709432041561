import { BulkUpdateStatus } from '../../enums/bulk-update-status';
import { StrictOmit } from '../../utils/types/strict-omit';
import { ModuleProperties, ModulePropertiesInitArgs } from '../estimate/modules/module-properties';

import { EstimateService } from './estimate-service';

/** Term subcategory. */
export class TermSubcategory extends ModuleProperties {

  /**
   * ID.
   * Might be "null" in case it is custom subcategory.
   */
  public readonly id: number | null;

  /**
   * Category ID.
   * Might be "null" in case it is custom subcategory.
   */
  public readonly categoryId: number | null;

  /** Name. */
  public readonly name: string;

  /** Services. */
  public readonly services: readonly EstimateService[];

  /** Bulk update status. */
  public readonly bulkUpdateStatus: BulkUpdateStatus;

  public constructor(data: TermSubcategoryInitArgs) {
    super(data);

    this.id = data.id;
    this.categoryId = data.categoryId;
    this.name = data.name;
    this.services = data.services;
    this.bulkUpdateStatus = data.bulkUpdateStatus;
  }

  /**
   * Whether provided subcategory is custom.
   * @param subcategory Estimate service subcategory.
   */
  public static isCustom(subcategory: TermSubcategory): subcategory is CustomTermSubcategory {
    return subcategory.id === null;
  }

  /**
   * Whether provided subcategory is standard.
   * @param subcategory Estimate service subcategory.
   */
  public static isStandard(subcategory: TermSubcategory): subcategory is StandardTermSubcategory {
    return subcategory.id !== null;
  }
}

export type TermSubcategoryInitArgs = ModulePropertiesInitArgs &
  Pick<TermSubcategory, 'id' | 'categoryId' | 'name' | 'services' | 'bulkUpdateStatus'>;

type StandardTermSubcategory = StrictOmit<TermSubcategory, 'id' | 'categoryId'> & {

  /** ID. */
  readonly id: number;

  /** Category ID. */
  readonly categoryId: number;
};

type CustomTermSubcategory = StrictOmit<TermSubcategory, 'id' | 'categoryId'> & {

  /** ID. */
  readonly id: null;

  /** Category ID. */
  readonly categoryId: null;
};
