import { Injectable, inject } from '@angular/core';

import { StandardMaterialGroup } from '@dartsales/common/core/models/estimate/modules/material/data/standard-material-group';
import { MaterialPricingNoOverride } from '@dartsales/common/core/models/estimate/modules/material/material-pricing-no-override';
import { EditStandardMaterialGroup } from '@dartsales/common/core/models/estimate/modules/material/data/edit-standard-matreial-group';
import { ModuleLockablePercentsStatus } from '@dartsales/common/core/models/estimate/module-lockable-percents';
import { PartFilterField, partsFilterHelper } from '@dartsales/common/core/models/filters/multi-condition-filters/part-search-filters/part-filter-fields';

import { IMapper } from '../../../mappers';
import { BatchEditLockablePercentsStatusDto, EditEstimateStandardMaterialGroupDto, StandardMaterialGroupDto } from '../../../dto/estimate/modules/material/data/standard-material-group.dto';
import { AmountPercentMapper } from '../../amount-percent.mapper';
import { BasePricingMapper } from '../../../pricing/base-pricing.mapper';
import { FilterConditionBlockMapper } from '../../../filters/filter-condition-block.mapper';

import { StandardMaterialItemsGroupMapper } from './standard-material-item.mapper';
import { MaterialLockablePercentsStatusMapper } from './material-lockable-percents-status.mapper';

/** Standard material group entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class StandardMaterialGroupMapper implements IMapper<StandardMaterialGroupDto, StandardMaterialGroup> {

  private readonly partFilterMapper = inject<FilterConditionBlockMapper<PartFilterField>>(FilterConditionBlockMapper);

  private readonly basePricingMapper = inject(BasePricingMapper);

  private readonly amountPercentMapper = inject(AmountPercentMapper);

  private readonly standardMaterialItemsGroupMapper = inject(StandardMaterialItemsGroupMapper);

  private readonly moduleLockablePercentsStatusMapper = inject(MaterialLockablePercentsStatusMapper);

  /** @inheritdoc */
  public fromDto(dto: StandardMaterialGroupDto): StandardMaterialGroup {
    const partsGroups = dto.partGroups
      .map(partGroup => this.standardMaterialItemsGroupMapper.fromDto(partGroup));

    return new StandardMaterialGroup({
      pricing: new MaterialPricingNoOverride({
        ...this.basePricingMapper.fromDto(dto),
        totalDirectCost: dto.totalDirectCost,
        unitCost: dto.unitCost,
        warranty: this.amountPercentMapper.fromDto(dto.warranty),
        freight: this.amountPercentMapper.fromDto(dto.freight),
        salesTax: this.amountPercentMapper.fromDto(dto.salesTax),
        quantity: dto.quantity,
        listPrice: dto.listPrice,
      }),
      id: dto.id,
      name: dto.name,
      isReadonly: dto.isReadOnly,
      conditions: dto.conditionBlocks.map(condition => this.partFilterMapper.fromDto(condition, partsFilterHelper)),
      partsGroups,
      lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.fromDto(dto.lockablePercentsStatus),
    });
  }

  /** @inheritdoc */
  public toDto(data: StandardMaterialGroup): StandardMaterialGroupDto {
    const pricingDto = this.basePricingMapper.toDto(data.pricing);

    return {
      ...pricingDto,
      totalDirectCost: data.pricing.totalDirectCost,
      unitCost: data.pricing.unitCost,
      directCost: pricingDto.directCost,
      warranty: data.pricing.warranty,
      escalation: data.pricing.escalation,
      freight: data.pricing.freight,
      salesTax: data.pricing.salesTax,
      id: data.id,
      name: data.name,
      isReadOnly: data.isReadonly,
      quantity: data.pricing.quantity,
      listPrice: data.pricing.listPrice,
      conditionBlocks: data.conditions.map(condition => this.partFilterMapper.toDto(condition, partsFilterHelper)),
      partGroups: data.partsGroups.map(partGroupData => this.standardMaterialItemsGroupMapper.toDto(partGroupData)),
      lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.toDto(data.lockablePercentsStatus),
    };
  }

  /**
   * Map editable estimate standard group to dto.
   * @param data Editable estimate standard group.
   */
  public toEditDto(data: EditStandardMaterialGroup): EditEstimateStandardMaterialGroupDto {
    return {
      percents: {
        warranty: data?.percents?.warrantyPercent,
        escalation: data?.percents?.escalationPercent,
        freight: data?.percents?.freightPercent,
        salesTax: data?.percents?.salesTaxPercent,
        contingency: data?.percents?.contingencyPercent,
        grossMargin: data?.percents?.grossMarginPercent,
      },
      isReadOnly: data.isReadonly,
    };
  }

  /**
   * Map to batch edit lockable percents status DTO.
   * @param itemId Group ID.
   * @param properties Lockable percents with status.
   */
  public toBatchEditLockablePercentsDto(
    itemId: StandardMaterialGroup['id'],
    properties: Partial<ModuleLockablePercentsStatus>,
  ): BatchEditLockablePercentsStatusDto {
    return {
      standardMaterials: [
        {
          standardGroupId: itemId,
          lockablePercentsStatus: this.moduleLockablePercentsStatusMapper.toEditDto(properties),
        },
      ],
    };
  }
}
