import { AbstractMaterialPricing, AbstractMaterialPricingInitArgs } from './abstract-material-pricing';

/** Material pricing without override properties. */
export class MaterialPricingNoOverride extends AbstractMaterialPricing {

  /** Quantity. */
  public readonly quantity: number;

  /** List price. */
  public readonly listPrice: number;

  public constructor(data: MaterialPricingNoOverrideInitArgs) {
    super(data);
    this.quantity = data.quantity;
    this.listPrice = data.listPrice;
  }
}

type MaterialPricingNoOverrideInitArgs = Pick<MaterialPricingNoOverride, 'quantity' | 'listPrice'> & AbstractMaterialPricingInitArgs;
