import { LaborRoleRateType } from '@dartsales/common/core/enums/labor-role-rate-type';
import { RateType } from '@dartsales/common/core/enums/rate-type';
import { Overridable } from '@dartsales/common/core/models/overridable';

import { ConfirmedOverridable } from '../../../confirmed-overridable';

import { ExpensesRole } from './expenses-role';

/** Expenses employee. */
export class ExpensesEmployee {
  /** ID (uuid). */
  public readonly id: string;

  /** Order index. */
  public readonly order: number;

  /** Labor role ID. */
  public readonly roleId: ExpensesRole['id'];

  /** Labor role rate type. */
  public readonly roleRateType: LaborRoleRateType;

  /**
   * Travel cost name.
   * The name is formed by checking whether how much costs list items is overridden or not.
   * E.g. "Default" | "Custom two".
   */
  public readonly travelCostName: string;

  /** Quantity. */
  public readonly quantity: number;

  /** List of travel costs. */
  public readonly travelCosts: readonly ExpensesEmployeeTravelCost[];

  /** Name. */
  public readonly name: ConfirmedOverridable<string>;

  /** Is removed. */
  public readonly isRemoved: ConfirmedOverridable<boolean>;

  public constructor(data: ExpensesEmployeeInitArgs) {
    this.id = data.id;
    this.roleId = data.roleId;
    this.roleRateType = data.roleRateType;
    this.travelCostName = data.travelCostName;
    this.order = data.order;
    this.quantity = data.quantity;
    this.travelCosts = data.travelCosts;
    this.name = data.name;
    this.isRemoved = data.isRemoved;
  }
}

type ExpensesEmployeeInitArgs = ExpensesEmployee;

/** Expenses employee travel cost. */
export class ExpensesEmployeeTravelCost {
  /**
   * Travel cost id.
   * "Null" is possible if travelCost is custom.
   */
  public readonly id: string;

  /** Name. */
  public readonly name: string;

  /** Rate type. */
  public readonly rateType: RateType;

  /** Quantity. */
  public readonly quantity: number;

  /** Rate. */
  public readonly rate: Overridable<number>;

  public constructor(data: ExpensesEmployeeTravelCostAmountInitArgs) {
    this.id = data.id;
    this.name = data.name;
    this.rateType = data.rateType;
    this.quantity = data.quantity;
    this.rate = data.rate;
  }
}

type ExpensesEmployeeTravelCostAmountInitArgs = ExpensesEmployeeTravelCost;
