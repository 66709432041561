import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import { TermSubcategory } from '@dartsales/common/core/models/estimate-services/term-subcategory';
import { buildHttpParams } from '@dartsales/common/core/utils/build-http-params';
import { TermMapDto } from '@dartsales/common/core/services/mappers/dto/estimate-services/term-map.dto';
import { TermMap } from '@dartsales/common/core/models/term-map';
import { TermMapMapper } from '@dartsales/common/core/services/mappers/term-map.mapper';

import {
  EditEstimateService,
  EditEstimateServiceData,
  EstimateService,
} from '../../models/estimate-services/estimate-service';
import { Term, TermEdit } from '../../models/estimate-services/term';
import { TermDto } from '../mappers/dto/estimate-services/term.dto';
import { EstimateServiceDto } from '../mappers/dto/estimate-services/estimate-service.dto';
import { AppErrorMapper } from '../mappers/errors/app-error.mapper';
import { TermMapper } from '../mappers/estimate-services/term.mapper';
import { TermDuplicationMapper } from '../mappers/estimate-services/term-duplication.mapper';
import { EstimateServiceMapper } from '../mappers/estimate-services/estimate-service.mapper';
import { OverviewAlternateEstimate } from '../../models/estimate/alternate-estimate';
import { OverviewAlternateEstimateDto } from '../mappers/dto/estimate/alternate-estimate.dto';
import { Estimate, EstimateId } from '../../models/estimate/estimate';
import { PalettePart } from '../../models/components/palette/palette-part';
import { TermSummaryMapper } from '../mappers/estimate-services/term-summary.mapper';
import { TermSummary } from '../../models/estimate-services/term-summary';
import { TermSummaryDto } from '../mappers/dto/estimate-services/term-summary.dto';
import { TermCompoundEscalationParams } from '../../models/estimate-services/term-compound-escalation-params';
import { TermServiceDto } from '../mappers/dto/estimate/term-service-modules.dto';
import { TermService } from '../../models/estimate-services/term-service';
import { TermServiceMapper } from '../mappers/estimate-services/term-service.mapper';
import { EstimateServicePartMapper } from '../mappers/estimate-services/estimate-service-part.mapper';

import { AppUrlsConfig } from './app-urls.config';

/** Create estimate service arguments. */
type CreateEstimateServiceFromServiceArgs = {

  /** Service IDs. */
  readonly serviceIds: readonly EstimateService['id'][];

  /** Term ID. */
  readonly termId: TermSummary['id'];
};

/** Estimate services API service. */
@Injectable({
  providedIn: 'root',
})
export class EstimateServicesApiService {

  private readonly apiUrls = inject(AppUrlsConfig);

  private readonly http = inject(HttpClient);

  private readonly estimateServiceMapper = inject(EstimateServiceMapper);

  private readonly termServiceMapper = inject(TermServiceMapper);

  private readonly estimateServiceTermMapper = inject(TermMapper);

  private readonly termMapMapper = inject(TermMapMapper);

  private readonly termSummaryMapper = inject(TermSummaryMapper);

  private readonly termDuplicationMapper = inject(TermDuplicationMapper);

  private readonly appErrorMapper = inject(AppErrorMapper);

  private readonly estimateServicePartMapper = inject(EstimateServicePartMapper);

  /**
   * Update an estimate service.
   * @param updatedServiceData Updated service data.
   */
  public update(updatedServiceData: EditEstimateServiceData): Observable<void> {
    return this.http.put<void>(
      this.apiUrls.estimateServicesApi.update(updatedServiceData.id),
      this.estimateServiceMapper.toEditDataDto(updatedServiceData),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Update estimate service.
   * @param updatedService Updated service with overrides.
   */
  public updateWithOverrides(updatedService: EditEstimateService): Observable<void> {
    return this.http.put<void>(
      this.apiUrls.estimateServicesApi.update(updatedService.id),
      this.estimateServiceMapper.toDto(updatedService),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Creates new estimate service.
   * @param args Arguments.
   */
  public create(args: {
    readonly subcategoryId: TermSubcategory['id'];
    readonly termId: TermSummary['id'];
    readonly name: string;
    readonly description: string;
  }): Observable<void> {
    const { subcategoryId, termId, name, description } = args;
    const params = buildHttpParams({
      estimateTermId: termId,
      subCategoryId: subcategoryId,
    });
    return this.http.post<void>(
      this.apiUrls.estimateServicesApi.create,

      // Quantity is a required field by the BE.
      // At this point we don't have any requirements to create more than 1 service at a time.
      { name, description, quantity: 1 },
      { params },
    );
  }

  /**
   * Delete a estimate service by ID.
   * @param id Estimate service ID.
   */
  public delete(id: EstimateService['id']): Observable<void> {
    return this.http.delete<void>(
      this.apiUrls.estimateServicesApi.delete(id),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Create alternate estimate by term.
   * @param termId Term id.
   */
  public createAlternateEstimateFromTerm(
    termId: EstimateService['termId'],
  ): Observable<OverviewAlternateEstimate['id']> {
    return this.http.post<OverviewAlternateEstimateDto['id']>(
      this.apiUrls.termsApi.createAlternateFromTerm(termId),
      null,
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Create alternate estimates from all terms.
   * @param estimateId Estimate ID.
   * @param termIds Term ids.
   * @returns Number of a term remained after terms conversion.
   */
  public createAlternateEstimatesFromAllTerms(
    estimateId: Estimate['id'],
    termIds: readonly EstimateService['termId'][],
  ): Observable<TermMap[]> {
    const params = buildHttpParams({
      estimateId,
    });

    return this.http.post<TermMapDto[]>(
      this.apiUrls.termsApi.createAlternatesFromAllTerms,
      termIds,
      { params },
    ).pipe(
      map(dtos => dtos.map(dto => this.termMapMapper.fromDto(dto))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Create estimate service part.
   * @param serviceId Service ID.
   * @param palettePartIds Palette part IDs.
   */
  public createEstimateServicePart(
    serviceId: EstimateService['id'],
    palettePartIds: readonly PalettePart['id'][],
  ): Observable<void> {
    const params = buildHttpParams({
      serviceId,
    });
    const dto = this.estimateServicePartMapper.toCreateFromPaletteDto(palettePartIds);

    return this.http.post<void>(
      this.apiUrls.estimateServicesApi.createServicePart(serviceId),
      dto,
      { params },
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Create estimate service part from symbol.
   * @param serviceId Service ID.
   * @param paletteSymbolIds Palette symbol IDs.
   */
  public createEstimateServicePartFromSymbol(
    serviceId: EstimateService['id'],
    paletteSymbolIds: readonly PalettePart['id'][],
  ): Observable<void> {
    const params = buildHttpParams({
      serviceId,
    });
    const dto = this.estimateServicePartMapper.toCreateFromPaletteDto(paletteSymbolIds);

    return this.http.post<void>(
      this.apiUrls.estimateServicesApi.createServicePartFromSymbols(serviceId),
      dto,
      { params },
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Delete estimate service part.
   * @param serviceId Service ID.
   * @param servicePartId Service part ID.
   */
  public deleteEstimateServicePart(
    serviceId: EstimateService['id'],
    servicePartId: PalettePart['id'],
  ): Observable<void> {
    return this.http.delete<void>(this.apiUrls.estimateServicesApi.deleteServicePart(serviceId, servicePartId)).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Create a new estimate service from organization service.
   * @param args Arguments.
   */
  public createFromTemplate(args: CreateEstimateServiceFromServiceArgs): Observable<EstimateService['id']> {
    const { serviceIds, termId } = args;
    const params = buildHttpParams({
      estimateTermId: termId,
    });
    return this.http.post<EstimateServiceDto['id']>(
      this.apiUrls.estimateServicesApi.createFromTemplates,
      serviceIds,
      { params },
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   *  Get terms.
   * @param estimateId Estimate ID.
   */
  public getTerms(
    estimateId: Estimate['id'],
  ): Observable<TermSummary[]> {
    const params = buildHttpParams({
      estimateId,
    });
    return this.http.get<TermSummaryDto[]>(this.apiUrls.termsApi.termCollection, { params }).pipe(
      map(dtos => dtos.map(dto => this.termSummaryMapper.fromDto(dto))),
    );
  }

  /**
   * Create new term.
   * @param estimateId Estimate ID.
   * @param termNumber Term number.
   */
  public createTerm(
    estimateId: Estimate['id'],
  ): Observable<void> {
    const params = buildHttpParams({
      estimateId,
    });
    return this.http.post<void>(this.apiUrls.termsApi.termCollection, {}, { params });
  }

  /**
   * Duplicate term.
   * @param termId Term ID to duplicate.
   * @param count Count of duplicates.
   */
  public duplicateTerm(
    termId: TermSummary['id'],
    count: number,
  ): Observable<void> {
    const dto = this.termDuplicationMapper.toDto(count);
    return this.http.post<void>(this.apiUrls.termsApi.termDuplicate(termId), dto);
  }

  /**
   * Delete term.
   * @param termId Term ID.
   */
  public deleteTerm(termId: TermSummary['id']): Observable<void> {
    return this.http.delete<void>(this.apiUrls.termsApi.termDetails(termId));
  }

  /**
   * Save term overrides.
   * @param termId Term ID.
   * @param term Term edit model.
   */
  public saveTermOverrides(
    termId: Term['id'],
    term: TermEdit,
  ): Observable<void> {
    const dto = this.estimateServiceTermMapper.toEditDto(term);
    return this.http.put<void>(this.apiUrls.termsApi.termDetails(termId), dto);
  }

  /**
   * Gets service term by id.
   * @param id Term ID.
   */
  public getTermDetails(id: Term['id']): Observable<Term> {
    return this.http.get<TermDto>(this.apiUrls.termsApi.termDetails(id)).pipe(
      map(dto => this.estimateServiceTermMapper.fromDto(dto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Get term service data by service IDs.
   * @param serviceId Service ID.
   */
  public getTermService(serviceId: EstimateService['id']): Observable<TermService> {
    return this.http.get<TermServiceDto>(this.apiUrls.estimateServicesApi.getById(serviceId)).pipe(
      map(dto => this.termServiceMapper.fromDto(dto)),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Gets term services by term ID.
   * @param id Term ID.
   */
  public getTermServices(id: Term['id']): Observable<TermService[]> {
    return this.http.get<TermServiceDto[]>(this.apiUrls.estimateServicesApi.getTermServices, {
      params: {
        estimateTermId: id,
      },
    }).pipe(
      map(dto => dto.map(item => this.termServiceMapper.fromDto(item))),
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Update terms order.
   * @param terms Terms in expected order.
   */
  public updateOrder(terms: readonly TermSummary[]): Observable<void> {
    const dtos = terms.map((item, order) => this.estimateServiceTermMapper.mapTermToOrderDto(item, order));
    return this.http.post<void>(
      this.apiUrls.termsApi.updateOrder,
      { resourceOrders: dtos },
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }

  /**
   * Renumber terms sequentially.
   * @param estimateId Estimate ID.
   */
  public renumber(estimateId: EstimateId): Observable<void> {
    return this.http.post<void>(
      this.apiUrls.termsApi.renumber(estimateId),
      null,
    );
  }

  /**
   * Update compound escalation.
   * @param escalationParams Escalation params.
   */
  public updateCompoundEscalation(escalationParams: TermCompoundEscalationParams): Observable<void> {
    const params = buildHttpParams({
      estimateId: escalationParams.estimateId,
    });

    return this.http.put<void>(
      this.apiUrls.termsApi.updateCompoundEscalation,
      {
        isCompoundEscalationEnabled: escalationParams.isEnabled,
        compoundEscalationPercent: escalationParams.percent,
      },
      { params },
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppError(),
    );
  }
}
