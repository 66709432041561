import { ClassProperties } from '@dartsales/common/core/utils/types/class-properties';
import { BulkUpdateStatus } from '@dartsales/common/core/enums/bulk-update-status';

import { OverviewAlternateEstimate } from '../alternate-estimate';
import { ModuleProperties } from '../modules/module-properties';
import { ModulesList } from '../modules-list';
import { FinalEstimateServiceTotals } from '../modules/service-totals/final-estimate-service-totals';
import { BasePricing } from '../../pricing/base-pricing';

import { FinalEstimateStatus } from './final-estimate-status';

/** Final estimate alternate. */
export type FinalEstimateAlternate = Pick<OverviewAlternateEstimate, 'id' | 'name' | 'description'>;

/** Final estimate. */
export class FinalEstimate extends BasePricing {

  /** ID. */
  public readonly id: number;

  /** Name. */
  public readonly name: string;

  /** Order. */
  public readonly order: number;

  /** Description. */
  public readonly description: string;

  /** Status. */
  public readonly status: FinalEstimateStatus;

  /** A list of included alternate estimates. */
  public readonly includedAlternateEstimates: readonly FinalEstimateAlternate[];

  /** Created date. */
  public readonly createdAt: Date | null;

  /** Updated date. */
  public readonly updatedAt: Date | null;

  /** Bulk update status. */
  public readonly bulkUpdateStatus: BulkUpdateStatus;

  public constructor(data: FinalEstimateInitArgs) {
    super(data);

    this.id = data.id;
    this.name = data.name;
    this.order = data.order;
    this.description = data.description;
    this.status = data.status;
    this.includedAlternateEstimates = data.includedAlternateEstimates;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.bulkUpdateStatus = data.bulkUpdateStatus;
  }
}

/** Version of final estimate with extended information. */
export class DetailedFinalEstimate extends FinalEstimate {

  /** Base estimate ID. */
  public readonly baseEstimateId: number;

  /** Modules. */
  public readonly modules: ModulesList;

  /** Totals by subcontractors, expenses, custom, labor, material. */
  public readonly modulesTotals: ModuleProperties;

  /** Totals by services. */
  public readonly serviceTotals: FinalEstimateServiceTotals;

  /** Base estimate tdc amount. */
  public readonly baseEstimateTdcAmount: number;

  public constructor(data: DetailedFinalEstimateInitArgs) {
    super(data);
    this.baseEstimateId = data.baseEstimateId;
    this.modules = data.modules;
    this.modulesTotals = data.modulesTotals;
    this.serviceTotals = data.serviceTotals;
    this.baseEstimateTdcAmount = data.baseEstimateTdcAmount;
  }
}

type FinalEstimateInitArgs = ClassProperties<FinalEstimate>;
type DetailedFinalEstimateInitArgs = ClassProperties<DetailedFinalEstimate>;

/** Final estimate summaries. */
export type FinalEstimateSummaries = Pick<DetailedFinalEstimate,
  | 'sellPrice'
  | 'contingency'
  | 'margin'
  | 'directCost'
  | 'modules'
  | 'modulesTotals'
  | 'serviceTotals'
>;

/** Final estimate edit data. */
export type FinalEstimateEditData = Pick<FinalEstimate, 'name' | 'description' | 'status'> & {

  /** List of alternate estimate IDs to include in final estimate. */
  readonly alternateEstimateIds: readonly FinalEstimateAlternate['id'][];
};
