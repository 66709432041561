import { BulkUpdateStatus } from '../../enums/bulk-update-status';
import { ClassProperties } from '../../utils/types/class-properties';
import { StrictOmit } from '../../utils/types/strict-omit';
import { BasePricing } from '../pricing/base-pricing';

import { AmountPercent } from './amount-percent';
import { Estimate } from './estimate';

/** Version of alternate estimate with brief information. */
export class OverviewAlternateEstimate extends BasePricing {

  /** ID. */
  public readonly id: number;

  /** Name. */
  public readonly name: string;

  /** Description. */
  public readonly description: string;

  /** Created date. */
  public readonly createdAt: Date | null;

  /** Updated date. */
  public readonly updatedAt: Date | null;

  /** Order. */
  public readonly order: number;

  /** Bulk update status. */
  public readonly bulkUpdateStatus: BulkUpdateStatus;

  public constructor(data: OverviewAlternateEstimateInitArgs) {
    super({
      ...data,

      /* In this case, we do not know TDC as a percentage, since there is no algorithm on how to calculate it. */
      tdc: new AmountPercent({ amount: data.tdcAmount }),
    });

    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.order = data.order;
    this.bulkUpdateStatus = data.bulkUpdateStatus;
  }
}

/** Overview alternate estimate initial arguments. */
export type OverviewAlternateEstimateInitArgs = ClassProperties<StrictOmit<OverviewAlternateEstimate, 'tdc'>> & {

  /** Tdc amount. */
  readonly tdcAmount: number;
};

/** Data required to create a new alternate estimate. */
export type CreateAlternateEstimateData = Pick<OverviewAlternateEstimate, 'description' | 'name'> & {

  /** Base estimate ID for creating Alternative. Null if estimate isn't merged in alternate. */
  readonly estimateId: Estimate['id'] | null;
};

/** Data required to edit an alternate estimate. */
export type EditAlternateEstimateData = Pick<OverviewAlternateEstimate, 'name' | 'description'>;
