import { Injectable, inject } from '@angular/core';

import { Module } from '@dartsales/common/core/models/estimate/module';
import { LaborModule } from '@dartsales/common/core/models/estimate/modules/labor/labor-module';
import { LaborModuleData } from '@dartsales/common/core/models/estimate/modules/labor/labor-module-data';

import { IMapper } from '../../../mappers';
import { LaborModuleDto } from '../../../dto/estimate/modules/labor/labor-module.dto';

import { LaborRoleHoursPricingMapper } from './labor-role-hours-pricing.mapper';
import { LaborModulePropertiesMapper } from './labor-module-properties.mapper';

/** Labor module entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class LaborModuleMapper implements
  IMapper<LaborModuleDto, LaborModule> {

  private readonly propertiesMapper = inject(LaborModulePropertiesMapper);

  private readonly laborRoleHoursPricingMapper = inject(LaborRoleHoursPricingMapper);

  /** @inheritdoc */
  public fromDto(dto: LaborModuleDto): LaborModule {
    return new Module({
      bulkUpdateStatus: dto.bulkUpdateStatus,
      properties: this.propertiesMapper.fromDto(dto.properties),
      data: new LaborModuleData({
        laborRoleHoursPricings: dto.data.roles.map(value => this.laborRoleHoursPricingMapper.fromDto(value)),
      }),
    });
  }

  /** @inheritdoc */
  public toDto(data: LaborModule): LaborModuleDto {
    return {
      bulkUpdateStatus: data.bulkUpdateStatus,
      properties: this.propertiesMapper.toDto(data.properties),
      data: {
        roles: data.data.laborRoleHoursPricings.map(value => this.laborRoleHoursPricingMapper.toDto(value)),
      },
    };
  }
}
