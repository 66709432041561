import { Overridable } from '@dartsales/common/core/models/overridable';

import { AbstractMaterialPricing, AbstractMaterialPricingInitArgs } from './abstract-material-pricing';

/** Base material pricing with override properties. */
export class MaterialPricingWithOverride extends AbstractMaterialPricing {

  /** Overridable quantity. */
  public readonly quantity: Overridable<number>;

  /** List price. */
  public readonly listPrice: Overridable<number>;

  public constructor(data: MaterialPricingWithOverrideInitArgs) {
    super(data);
    this.quantity = data.quantity;
    this.listPrice = data.listPrice;
  }
}

export type MaterialPricingWithOverrideInitArgs = Pick<MaterialPricingWithOverride, 'quantity' | 'listPrice'>
  & AbstractMaterialPricingInitArgs;
