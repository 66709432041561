import { Injectable, inject } from '@angular/core';

import { ExpensesModuleData } from '@dartsales/common/core/models/estimate/modules/expenses/expenses-module-data';
import { ExpensesModule } from '@dartsales/common/core/models/estimate/modules/expenses/expenses-module';

import { EditExpensesModuleDto, ExpensesModuleDto } from '../../../dto/estimate/modules/expenses/expenses-module.dto';
import { IMapperFromDto, IMapperToDto } from '../../../mappers';
import { ModulePropertiesMapper } from '../module-properties.mapper';

import { ExpensesPricingMapper } from './expenses-pricing.mapper';
import { ExpensesRoleMapper } from './expenses-role.mapper';
import { ExpensesTravelCostMapper } from './expenses-travel-cost.mapper';
import { ExpensesEmployeeMapper } from './expenses-employee.mapper';

/** Expenses module entity mapper. */
@Injectable({
  providedIn: 'root',
})
export class ExpensesModuleMapper implements
  IMapperFromDto<ExpensesModuleDto, ExpensesModule>,
  IMapperToDto<EditExpensesModuleDto, ExpensesModule> {

  private readonly propertiesMapper = inject(ModulePropertiesMapper);

  private readonly expensesRoleMapper = inject(ExpensesRoleMapper);

  private readonly expensesTravelCostMapper = inject(ExpensesTravelCostMapper);

  private readonly expensesEmployeeMapper = inject(ExpensesEmployeeMapper);

  private readonly expensesPricingMapper = inject(ExpensesPricingMapper);

  /** @inheritdoc */
  public fromDto(dto: ExpensesModuleDto): ExpensesModule {
    const roles = dto.data?.roles?.map(role => this.expensesRoleMapper.fromDto(role)) ?? [];
    const travelCosts = dto.data?.travelCosts?.map(travelCost => this.expensesTravelCostMapper.fromDto(travelCost)) ?? [];
    const employees = dto.data?.employees?.map(e => this.expensesEmployeeMapper.fromDto(e, travelCosts)) ?? [];
    const pricingList = dto.data?.summaries?.map(item => this.expensesPricingMapper.fromDto(item, { travelCosts })) ?? [];

    const moduleData = new ExpensesModuleData({
      roles,
      travelCosts,
      employees,
      pricingList,
    });

    return new ExpensesModule({
      bulkUpdateStatus: dto.bulkUpdateStatus,
      properties: this.propertiesMapper.fromDto(dto.properties),
      data: moduleData,
    });
  }

  /** @inheritdoc */
  public toDto(data: ExpensesModule): EditExpensesModuleDto {
    return {
      bulkUpdateStatus: data.bulkUpdateStatus,
      properties: this.propertiesMapper.toDto(data.properties),
      data: {
        travelCosts: data.data.travelCosts.map(travelCost => this.expensesTravelCostMapper.toEditDto(travelCost)),
        employees: data.data.employees.map(e => this.expensesEmployeeMapper.toEditDto(e)),
      },
    };
  }
}
